import { gtag } from "./events";

// end polyfill...
export class GoogleAnalyticsEvents implements EventTracker {

    gtag?: Gtag.Gtag | null;
    constructor(gtag: Gtag.Gtag | null) {
        this.gtag = gtag;
    }

    googleAnalyticsLoaded() {
        gtag?.("event", "google_analytics_initialized");
    }

    productTopicClicked(productId: string, productName: string, topicName: string) {
        gtag?.("event", "topic_clicked", {
            'event_label': "Product ID: " + productId + "Product Name: " + productName + " Topic: " + topicName
        });
    }

    summaryLinkClicked(productID: string, productName: string, summaryName: string) {
        gtag?.("event", "summary_clicked", {
            'event_label': "Product ID: " + productID + "Product Name: " + productName + " Summary: " + summaryName
        });
    }

    graphNodeClicked(collectionName: string) {
        gtag?.("event", "node_clicked", {
            'event_label': "Collection Name: " + collectionName
        });
    }

    textGraphicsModeSwitch(mode: string) {
        gtag?.("event", "text_graphic_mode", {
            'event_label': "Mode: " + mode
        });
    }

    nodeRangeChange(rangeVal: string | number | string[]) {
        gtag?.("event", "node_range_changed", {
            'event_label': "Range: " + rangeVal
        });
    }

    productLinkClicked(href: string) {
        gtag?.("event", "product_link_clicked", {
            'event_label': "Link: " + href
        });
    }

    categoryChanged(category: string) {
        gtag?.("event", "category_changed", {
            'event_label': "Category: " + category
        });
    }

    subcategoryChanged(subcategory: string) {
        gtag?.("event", "subcategory_changed", {
            'event_label': "Subcategory: " + subcategory
        });
    }

    ratingChanged(rating: string) {
        gtag?.("event", "rating_changed", {
            'event_label': "Rating: " + rating
        });
    }

    productToolsToggled(toggleState: string) {
        gtag?.("event", "product_tools_toggled", {
            'event_label': "ProductTools: " + toggleState
        });        
    }

    // Unused
    // productsVisibilityToggled(toggleState: string) {
    //     gtag?.("event", "products_visibility_toggled", {
    //         'event_label': "ProductsVisibility: " + toggleState
    //     });
    // }

    // productVisibilityToggled(toggleState: string) {
    //     gtag?.("event", "product_visibility_toggled", {
    //         'event_label': "ProductVisibility: " + toggleState
    //     });
    // }  

    productToolSelected(productTool: string) {
        gtag?.("event", "product_tool_selected", {
            'event_label': "ProductTool: " + productTool
        });
    }

    // Unused
    // rankingSelected(ranking: string) {
    //     gtag?.("event", "ranking_selected", {
    //         'event_label': "RankingSelected: " + ranking
    //     });
    // }

    // rankingAdded(ranking: string) {
    //     gtag?.("event", "ranking_added", {
    //         'event_label': "RankingAdded: " + ranking
    //     });
    // }

    // rankingRemoved(ranking: string) {
    //     gtag?.("event", "ranking_removed", {
    //         'event_label': "RankingRemoved: " + ranking
    //     });
    // }

    // rankingEdited(ranking: string) {
    //     gtag?.("event", "ranking_edited", {
    //         'event_label': "RankingEdited: " + ranking
    //     });
    // }    
}
