import { MatomoTracker } from "./MatomoTracker";

export class MatomoEvents implements EventTracker {
  textGraphicsModeSwitch(mode: string) {
      this._paq?.push(['trackEvent', 'Text Graphics Mode Switched', mode]);
  }

  productTopicClicked(productID: string, productName: string, topicName: string) {
    let eventName = productID + ' - ' + productName
    this._paq?.push(['trackEvent', 'Product Topic Clicked', eventName, topicName]);
  }

  _paq?: MatomoTracker | null;
  constructor(_paq: MatomoTracker) {
    this._paq = _paq;
  }

  productLinkClicked(link: string) {
    this._paq?.push(['trackEvent', "Product Link Clicked", link]);
  }

  summaryLinkClicked(productID: string, productName: string, summaryName: string) {
    let eventName = productID + ' - ' + productName
    this._paq?.push(["trackEvent", "Summary Link Clicked", eventName, summaryName]);
  }

  categoryChanged(category: string) {
    this._paq?.push(["trackEvent", "Category Changed", category]);
  }

  subcategoryChanged(subcategory: string) {
    this._paq?.push(["trackEvent", "Subcategory Changed", subcategory]);
  }

  ratingChanged(rating: string) {
    this._paq?.push(["trackEvent", "Rating Changed", rating]);
  }

  productToolsToggled(toggleState: string) {
    this._paq?.push(["trackEvent", "Product Tools Toggled", toggleState]);
  }

  // Unused
  // productsVisibilityToggled(toggleState: string) {
  //   this._paq?.push(["trackEvent", "Products Visibility Toggled", toggleState]);
  // }

  // productVisibilityToggled(toggleState: string) {
  //   this._paq?.push(["trackEvent", "Product Visibility Toggled", toggleState]);
  // }  

  productToolSelected(productTool: string) {
    this._paq?.push(["trackEvent", "Product Tool Selected", productTool]);
  }

  // Unused
  // rankingSelected(ranking: string) {
  //   this._paq?.push(["trackEvent", "Ranking Selected", ranking]);
  // }

  // rankingAdded(ranking: string) {
  //   this._paq?.push(["trackEvent", "Ranking Added", ranking]);
  // }

  // rankingRemoved(ranking: string) {
  //   this._paq?.push(["trackEvent", "Ranking Removed", ranking]);
  // }

  // rankingEdited(ranking: string) {
  //   this._paq?.push(["trackEvent", "Ranking Edited", ranking]);
  // }

  setTracker(_paq: MatomoTracker | null) {
    this._paq = _paq;
  }
}