import _ from "lodash";
import $ from "jquery";
import { GoogleAnalyticsEvents } from "./GoogleAnalyticsEvents";
import { MatomoEvents } from "./MatomoEvents";
import { MatomoTracker } from "./MatomoTracker";

// possibly unneeded polyfill for closest...
if (!Element.prototype.matches) {
    Element.prototype.matches =
        // @ts-ignore: Possibly missing stuff...
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (Element.prototype.matches.call(el, s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

export let gtag = window['gtag'] || undefined;

// we want to hand gtag down to the typescript layer...
let gaEvents = new GoogleAnalyticsEvents(gtag);
window['gaEvents'] = gaEvents;

// now let's set up matomo...
var matomoTask = window.setInterval(waitForMatomo, 1000);

// wait for matomo to be loaded. we can detect it in the "window" contexdt.
function waitForMatomo() {
    const matomo = window['Matomo'];
    if (matomo != null) {
        var _paq: MatomoTracker = window['_paq'];
        let matomoEvents = new MatomoEvents(_paq);
        window['matomoEvents'] = matomoEvents;
        if (shouldWeBind()) {
            doAllBinds(matomoEvents);
        }
        window.clearInterval(matomoTask);
    }
};

function doAllBinds(eventTracker: EventTracker) {
    bindProductTopicLink(eventTracker);
    bindSummaryLink(eventTracker);
    bindTextGraphicModeSwitch(eventTracker);
    bindProductLink(eventTracker);
}

function shouldWeBind() {
    if (window.location.pathname == "/comparison/") {
        return false;
    }
    return true;
}

document.addEventListener("DOMContentLoaded", function (event) {
    if (shouldWeBind()) {
        doAllBinds(gaEvents);
    }
});

function bindProductLink(eventTracker: EventTracker) {
    $(document).on("click", ".product-link", (event) => {
        let link = $(event.target).attr("href");
        eventTracker.productLinkClicked(link);
    });
}

// report on "Text/Graphic" mode switch for analytics...
function bindTextGraphicModeSwitch(eventTracker: EventTracker) {
    $(document).on("click", ".btn", (event) => {
        if ($(event.target).children("#radio-display-text").length > 0) {
            eventTracker.textGraphicsModeSwitch("text");
        } else if ($(event.target).children("#radio-display-graphic").length > 0) {
            eventTracker.textGraphicsModeSwitch("graphic");
        } else if ($(event.target).children("#radio-display-chart").length > 0) {
            eventTracker.textGraphicsModeSwitch("chart");
        }
    });
}

function bindSummaryLink(eventTracker: EventTracker) {
    $(document).on("click", "a.summary-link", (event) => {
        let summaryLink = (event.target as HTMLElement).closest("a.summary-link");
        if (summaryLink == null) {
            return;
        }
        let summaryName = summaryLink.querySelector(".summary-name")?.textContent?.trim();
        
        let productID = summaryLink.getAttribute("data-product-id");
        let productName = summaryLink.getAttribute("data-product-name");
        if (productID != null && productName != null && summaryName != null) {
            eventTracker.summaryLinkClicked(productID, productName, summaryName);
        }
    });
}

function bindProductTopicLink(eventTracker: EventTracker) {
    let topicLinks = document.getElementsByClassName("ga-event-product-topic-link");
    _.each(topicLinks, (topicLink) => {
        topicLink.addEventListener("click", (event) => {
            // we need to extract the name from .child-collection-name
            let topicNameElement = topicLink.querySelector(".child-collection-name > span");
            if (topicNameElement == null) {
                return;
            }
            let productID = topicLink.getAttribute("data-product-id");
            let productName = topicLink.getAttribute("data-product-name");
            let topicName = topicNameElement.textContent;
            eventTracker.productTopicClicked(productID, productName, topicName);
        });
    });
}

